<template>
  <div>
    <popup
      v-if="show"
      title="Home Sampling"
      description="Enter your details to book appointment for medical test , either for home sample pickup or on location testing"
      :inputs="inputs"
      layout="grid"
      posActionName="Book"
      @onCancel="$emit('onCancel')"
      @onCLickPositive="onClickBook"
      :processing="processing"
    />
  </div>
</template>

<script>
import Popup from "./Popup.vue";

export default {
  name: "BookAppointmentPopup",
  metaInfo: {
    title: "Book Appointment",
    // override the parent template and just use the above title only
    titleTemplate: null,
  },
  components: {
    popup: Popup,
  },
  mounted() {
    this.$Notifications.showLoading();
    this.$HTTP
      .get(this.$URLS.RATELIST.TESTS)
      .then((res) => {
        this.$Notifications.showLoading();
        var parsedRates = [];
        res.data.forEach((item) => {
          parsedRates.push({
            label: item.name,
            value: { name: item.name, id: item.id },
          });
        });
        this.inputs[4].options = parsedRates;
        setTimeout(() => {
          this.show = true;
          this.$Notifications.hideLoading();
        }, 1000);
      })
      .catch((err) => {
        console.log("err");
        console.log(err);
      });
  },
  data() {
    return {
      show: false,
      parsedRates: [],
      inputs: [
        {
          id: "name",
          placeholder: "Your full name",
          type: "text",
          name: "Fullname",
          required: false,
          val: "",
        },
        {
          id: "gender",
          placeholder: "Please select your gender",
          type: "select",
          name: "Gender",
          options: [
            {
              key: "male",
              value: "Male",
            },
            {
              key: "female",
              value: "Female",
            },
          ],
          required: false,
          val: "",
        },
        {
          id: "phone",
          placeholder: "Your phone number",
          type: "text",
          name: "Phone",
          required: false,
          val: "",
        },
        {
          id: "email",
          placeholder: "Your email address",
          type: "text",
          name: "Email",
          required: false,
          val: "",
        },
        {
          id: "test",
          placeholder: "Please select a test",
          type: "multiselect",
          name: "Lab Test Name",
          required: false,
          val: [],
        },
        {
          id: "dob",
          placeholder: "Your data of birth",
          type: "date",
          name: "Date Of Birth",
          required: false,
          val: "",
        },
        {
          id: "date",
          placeholder: "Please select a date",
          type: "date",
          name: "Date",
          required: false,
          val: "",
        },
        {
          id: "time",
          placeholder: "Please select a time",
          type: "time",
          name: "Time",
          required: false,
          val: "",
        },
        {
          id: "mode",
          placeholder: "home pickup or on-site",
          type: "select",
          name: "Mode",
          options: [
            {
              key: "home_sampling",
              value: "Home sample pickup",
            },
            {
              key: "visit_lab",
              value: "Visit lab yourself",
            },
          ],
          required: false,
          val: "",
        },
        {
          id: "address",
          placeholder: "Your full address",
          type: "text",
          name: "Address",
          required: false,
          val: "",
          conditional: {
            key: "mode",
            val: "home_sampling",
          },
        },
        {
          id: "city",
          placeholder: "Which city are you in ?",
          type: "text",
          name: "City",
          required: false,
          val: "",
          conditional: {
            key: "mode",
            val: "home_sampling",
          },
        },
      ],
      processing: false,
    };
  },
  methods: {
    onClickBook() {
      let name = this.inputs[0].val;
      let gender = this.inputs[1].val;
      let phone = this.inputs[2].val;
      let email = this.inputs[3].val;
      let tests = this.inputs[4].val;
      let dob = this.inputs[5].val;
      let date = this.inputs[6].val;
      let time = this.inputs[7].val;
      let mode = this.inputs[8].val;
      var address = "";
      var city = "";
      if (mode == "home_sampling") {
        address = this.inputs[9].val;
        city = this.inputs[10].val;
        if (!address || address == "") {
          this.$Notifications.showNotification(
            "warning",
            "Please enter your address"
          );
          return;
        } else if (!city || city == "") {
          this.$Notifications.showNotification(
            "warning",
            "Please enter your city name"
          );
          return;
        }
      }

      let finalDate = `${date} ${time}`;
      let data = {
        name: name,
        time: finalDate,
        phone: phone,
        gender: gender,
        email: email,
        lab_test_name: tests,
        address: address,
        city: city,
        mode: mode,
        dob: dob,
      };

      this.$HTTP
        .post(this.$URLS.APPOINTMENT.SAVE, data)
        .then((res) => {
          this.$emit("onSuccess");
          this.$Notifications.showNotification(
            "success",
            "Congratulations, your appointment has been booked"
          );
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
