<template>
  <div>
    <popup
      title="Cancel Appointment"
      description="You are about to cancel your appointment. Once you cancel , you cannot undo it. Are you sure ?"
      :inputs="inputs"
      posActionName="Yes , Cancel"
      negActionName="Abort"
      @onCancel="$emit('onCancel')"
      @onCLickPositive="onClickCancelAppointment"
      :processing="false"
    />
  </div>
</template>

<script>
import Popup from "./Popup.vue";
var qs = require("qs");
var axios = require("axios");

export default {
  name: "CancelAppointmentPopup",
  components: {
    popup: Popup,
  },
  props: ["appointmentId"],
  data() {
    return {
      processing: false,
      inputs: [],
    };
  },
  methods: {
    onClickCancelAppointment() {
      let id = this.appointmentId;
      this.$HTTP
        .patch(this.$URLS.APPOINTMENT.BY_ID.replace(":id", id), {
          status: "cancelled",
        })
        .then((res) => {
          this.$Notifications.showNotification(
            "success",
            "You appointment has been cancelled successfully"
          );
          this.$emit("onSuccess");
        })
        .catch((err) => {
          console.log("cancel appointment error");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
