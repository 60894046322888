<template>
  <div class="home">
    <div class="nav">
      <navbar @onClickBurger="onClickBurger" @onClickNavBt="onClickNavBt" />
    </div>

    <img class="svg" src="../assets/faq.svg" alt="" />
    <div class="faq-container">
      <span class="title">FAQs</span>
      <span class="desc"
        >Welcome to our FAQs page. Please browse through the following question.
        If you cannot find what you are looking for , then please feel free to
        contact us</span
      >

      <div class="faqs">
        <div
          class="faq"
          :class="{ 'faq-opened': item.opened }"
          v-for="(item, index) in faqs"
          :key="index"
          @click="item.opened = !item.opened"
        >
          <div class="top">
            <span class="question">{{ item.question }}</span>
            <img src="../assets/icons/ic_play_arrow.png" alt="" class="arrow" />
          </div>
          <span v-if="item.opened" class="answer">{{ item.answer }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
export default {
  name: "FAQs",
  components: {
    navbar: NavBar,
  },
  data() {
    return {
      faqs: [],
    };
  },
  methods: {
    getFaqs() {
      let ctx = this;
      this.$HTTP(this.$URLS.FAQ.LIST)
        .then(function (response) {
          let parsed = response.data.map((item) => {
            item.opened = false;
            return item;
          });
          parsed[0].opened = true;
          ctx.faqs = parsed;
        })
        .catch(function (error) {});
    },
    isMobileDevice() {
      this.isMobile = screen.width <= 690;
    },
    windowResized() {
      this.isMobileDevice();
    },
  },
  created() {
    this.isMobileDevice();
    this.getFaqs();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 690px) {
  .home {
    .faq-container {
      padding: 1rem 2rem !important;
      margin-top: 1rem !important;

      .title {
        font-size: 2.4rem !important;
      }

      .desc {
        padding-right: 1rem;
        font-size: 1.4rem !important;
      }
    }
  }
}

.home {
  position: relative;
  height: 100vh;

  .svg {
    position: absolute;
    z-index: 1;
    bottom: 2rem;
    left: 2rem;
  }
  .faq-container {
    height: calc(100% - 10rem);
    padding: 2rem 20%;
    z-index: 2;
    position: relative;
    width: 105%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.752);

    .title {
      font-size: 3rem;
      font-family: "P-600";
    }

    .desc {
      font-size: 1.6rem;
      font-family: "P-300";
    }

    .faqs {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;
      overflow-y: scroll;

      .faq-opened {
        .top {
          .arrow {
            transform: rotate(90deg) scale(1.25);
            filter: invert(70%) sepia(15%) saturate(1756%) hue-rotate(347deg)
              brightness(97%) contrast(95%);
          }
        }
      }

      .faq {
        background: rgba(255, 255, 255, 0.852);
        display: flex;
        flex-direction: column;
        border: 0.1rem solid lightgray;
        padding: 1rem;
        margin-bottom: 1rem;

        .top {
          display: flex;
          flex-direction: row;
          justify-content: center;

          .question {
            font-size: 1.6rem;
            font-family: "P-500";
            padding-right: 1rem;
            line-height: 3rem;

            &::before {
              content: "#";
              margin-right: 0.4rem;
              color: var(--clr-ylw);
            }
          }

          .arrow {
            width: 3rem;
            height: 3rem;
            margin-left: auto;
            transition: 0.25s all ease-in-out;
          }
        }

        .answer {
          font-size: 1.4rem;
          font-family: "P-300";
          margin-top: 1rem;
        }
      }
    }
  }
}
</style>
