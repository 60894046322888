const Notifications = require("../controllers/Notifications.js");

import axios from "axios";
// const baseURL = "http://localhost:3003/";
// const baseURL = "https://api.nxgen.internal.sawatechnologies.org/";
const baseURL = "https://api.nxgenlabs.com.pk/";

axios.defaults.baseURL = baseURL;

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API,
});

HTTP.interceptors.request.use(
  function (config) {
    Notifications.showLoading();
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  (response) => {
    Notifications.hideLoading();
    return response;
  },
  (error) => {
    console.log("ERROR==");
    console.log(error.response.status);
    Notifications.hideLoading();
    if (error.response && error.response.status === 401) {
      Notifications.showNotification(
        "danger",
        "Session timeout. Please login again!"
      );
      localStorage.clear();
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    } else if (error.response && error.response.data) {
      Notifications.showNotification("danger", error.response.data.message);
    } else {
      Notifications.showNotification("danger", error.message);
    }
    throw error;
  }
);

const URLS = {
  baseURL: baseURL,
  AUTH: {
    REGISTER: "patient/register",
    LOGIN: "patient/login",
    OTP: "patient/send/code?destination=:dest&type=:type",
    VERIFY: "patient/verify/otp?code=:code&destination=:dest",
    RESET_PASSWORD: "patient/reset/password",
  },
  FEEDBACK: {
    CREATE: "feedback/create",
  },
  NEWSLETTER: {
    SUBSCRIBE: "subscriber/add",
  },
  RATELIST: {
    TESTS: "ratelist/tests",
  },
  PACKAGE: {
    LIST: "package/list",
  },
  FAQ: {
    LIST: "faq/list",
  },
  RATE_LIST: "api/services-list",
  PACKAGES_LIST: "api/services-profile",
  APPOINTMENT: {
    SAVE: "appointment/create",
    EDIT: "api/appointment-edit",
    BY_ID: "appointment/:id",
    LIST: "appointment/list",
  },
  SAVE_FEEDBACK: "api/feedback-save",
};

export { HTTP, URLS };
