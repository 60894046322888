const Notifications = require("../controllers/Notifications.js");
import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
// import Reports from "../components/Reports.vue";
import Appointments from "../components/Appointments.vue";
import RateList from "../components/RateList.vue";
import Packages from "../components/Packages.vue";
import RatesServices from "../views/RatesService.vue";
import FAQs from "../views/FAQs.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";

const routes = [
  {
    path: "/",
    name: "Landing",
    component: () => import("../views/Landing.vue"),
  },
  {
    path: "/rs",
    name: "RatesPackages",
    component: RatesServices,
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: FAQs,
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { protected: true },
    children: [
      // {
      //   name: "Reports",
      //   path: "reports",
      //   component: Reports,
      //   meta: {
      //     tabIndex: 1
      //   }
      // },
      {
        name: "Appointments",
        path: "appointments",
        component: Appointments,
        meta: {
          tabIndex: 2,
        },
      },
      {
        name: "RateList",
        path: "ratelist",
        component: RateList,
        meta: {
          tabIndex: 3,
        },
      },
      {
        name: "Packages",
        path: "packages",
        component: Packages,
        meta: {
          tabIndex: 4,
        },
      },
    ],
  },
];

// const routes = [
//   {
//     path: "/",
//     name: "UM",
//     component: () =>
//       import("../views/UnderMaintainance.vue")
//   },
// ]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.protected)) {
    if (!localStorage.getItem("token")) {
      Notifications.showNotification(
        "warning",
        "Please login before continuing..."
      );
      next({
        path: "/",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
