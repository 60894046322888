import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Meta from "vue-meta";

import { HTTP, URLS } from "./controllers/Network";
const Notifications = require("./controllers/Notifications");

if (!Number.prototype.format) {
  Number.prototype.format = function () {
    let d = 2;
    let x = parseFloat(this.valueOf());
    if (!d) return x.toFixed(d); // don't go wrong if no decimal
    return x.toFixed(d).replace(/\.?0+$/, "");
  };
}

const app = createApp(App);
app.use(router);
app.config.globalProperties.$HTTP = HTTP;
app.config.globalProperties.$URLS = URLS;
app.config.globalProperties.$Notifications = Notifications;
app.mount("#app");
