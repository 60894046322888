<template>
  <div class="home">
    <div class="nav">
      <navbar @onClickBurger="onClickBurger" @onClickNavBt="onClickNavBt" />
    </div>

    <div class="privacy-container">
      <span class="title">Privacy Policy</span>

      <p><br /></p>

      <p class="desc">
        Nxgen Labs, the creator of this privacy policy ensures its commitment to
        your privacy with regard to the protection of your information. This
        privacy policy contains information about the Nxgen labs Platform (see
        the Terms on the website/Platform, which are incorporated with the
        Privacy Policy, and vice versa). In order to provide you with
        uninterrupted use of our Platform, we may collect and, in some
        circumstances, disclose information about you. Such information may be
        classified as personal information.
        <br /><br />
        If you have any queries or concerns regarding this privacy policy, you
        should contact Nxgen Labs support team and/or Helpline number, which are
        ascertainable via the Platform.
        <br /><br />
        ANY CAPITALISED WORDS USED HENCEFORTH SHALL HAVE THE MEANING ACCORDED TO
        THEM UNDER THIS AGREEMENT. FURTHER, ALL HEADING USED HEREIN ARE ONLY FOR
        THE PURPOSE OF ARRANGING THE VARIOUS PROVISIONS OF THE AGREEMENT IN ANY
        MANNER. NEITHER THE USER NOR THE CREATERS OF THIS PRIVACY POLICY MAY USE
        THE HEADING TO INTERPRET THE PROVISIONS CONTAINED WITHIN IT IN ANY
        MANNER. THIS PRIVACY POLICY SHALL BE COTERMINOUS TO THE PRIVACY
        POLICYGOVERNING THE USE OF www.nxgenlabs.com.pk
        <br /><br />
      </p>

      <span class="subTitle">OVERVIEW</span>

      <p class="desc">
        This privacy policy applies to your information that we collect and
        receive on and through Nxgen Labs Platform; it does not apply to
        practices of businesses that we do not own or control or people we do
        not employ.
        <br /><br />
        By using this Platform, you agree to the terms of this privacy policy.
        Please read the following privacy policy to understand how your personal
        information will be treated as you use this site and its services. The
        following discloses our information gathering and dissemination
        practices.
        <br /><br />
      </p>

      <span class="subTitle">WE MAY COLLECT THE FOLLOWING INFORMATION</span>

      <p class="desc">
        Personal data of the user such as, but not limited to, your name, your
        age, date of birth, occupation and gender ; The user’s e-mail and
        contact information, GPS based location; The User’s tracking information
        such as, but not limited to the device ID, Google advertising ID and
        android ID; The user’s data sent across through the application. As a
        user of the Platform, you may provide information about yourself, your
        spouse or family, your friends, their health issues, gender of the
        patient, age of the patient, previous medication taken, previous medical
        conditions, allergies, etc.
        <br /><br />
      </p>

      <span class="subTitle">OUR USE OF YOUR INFORMATION</span>

      <p class="desc">
        The information provided by you shall be used to contact you when
        necessary. For more details about the nature of such communications,
        please refer to our Terms. We may use your tracking information to
        identify you and to gather broad demographic information. The
        information is also used to customize your experience of using the Nxgen
        Labs Platform.
        <br /><br />
        Nxgen Labs may use cookies to recognize returning users. This helps us
        improve your user experience. We also may use third party tools to
        better capture your navigation data and enhance your user experience of
        our Platform. You may disable cookies by changing your browser settings,
        if you like. However, this may decrease your user experience for using
        our Platform.
        <br /><br />
        Our potential use of cookies also means that data relating to traffic on
        our Platform and about the types of third-party websites that visitors
        to our website also visit are aggregated and shared with other
        participants in the third-party advertising program. The data that may
        be shared does not contain any personal information and cannot be used
        to identify any individual visitor.
        <br /><br />
        If you would like to delete cookies or instruct your web browser to
        delete or refuse cookies, you can change your cookie preferences by
        choosing the appropriate browser settings.
        <br /><br />
        This Privacy Policy does not cover the use of cookies by any third-party
        links. Nxgen Labs will not be liable for the personal privacy policies
        on these sites. Please be aware that the privacy policies of these
        websites could vary from our own.
        <br /><br />
      </p>

      <span class="subTitle">PROTECTION OF PERSONAL INFORMATION</span>

      <p class="desc">
        Nxgen Labs has adopted a protection of personal information policy which
        aims to protect your private life, in accordance with the applicable
        laws to this effect, Nxgen Labs has taken and applies appropriate
        security measures while using this site, including the encoding of
        certain data. However, public communication networks such as the
        internet are open systems with links shared by many users. You recognize
        that Nxgen Labs does not guarantee that some personal information won’t
        be intercepted and manipulated by third parties. For the purpose of
        operating this site, Nxgen Labs may collect some information, including
        personal information. This information will never be the object of a
        communication, transfer, sale, assignment or any other commercial
        transaction with third parties without your authorization. This
        information may however be transferred between various internal
        departments or with distributors adhering to a protection of personal
        information policy, for the purpose of administration and management. No
        Nxgen Labs representative is authorized to obtain personal or credit
        information from you over the phone. If this is the case, we ask you to
        contact us immediately in order for us to proceed with an internal
        investigation. Please note that this rule/clause is not applicable to
        those cases, in which we are obligated to share data with the government
        or relevant authorities.
        <br /><br />
      </p>

      <span class="subTitle">CONTROLLING YOUR PERSONAL INFORMATION</span>

      <p class="desc">
        We will generally not sell, distribute or lease your personal
        information to third parties unless we have your permission or are
        required to do so, by law. We may use your personal information to send
        you promotional information about third parties which we think you may
        find interesting if you tell us that you wish this to happen. If you
        believe that any information we are holding on you is incorrect or
        incomplete, please send us an email, as soon as possible. We will be
        happy to correct any information found to be incorrect.
        <br /><br />
      </p>

      <span class="subTitle">WEBSITE TERMS</span>

      <p class="desc">
        By downloading and/or using or accessing the Platform, you hereby agree
        to be bound by the terms of use/service, privacy policy, and other
        policies as set forth on the Platform. It is hereby further specified
        that all the terms and policies on the website shall be coterminous to
        these terms.
        <br /><br /><br /><br />
      </p>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    navbar: NavBar,
  },
  methods: {
    isMobileDevice() {
      this.isMobile = screen.width <= 690;
    },
    windowResized() {
      this.isMobileDevice();
    },
  },
  created() {
    this.isMobileDevice();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 690px) {
  .home {
    .privacy-container {
      padding: 1rem 2rem !important;
      margin-top: 1rem !important;

      .title {
        font-size: 2.4rem !important;
      }

      .desc {
        padding-right: 1rem;
        font-size: 1.4rem !important;
      }
    }
  }
}

.home {
  position: relative;
  height: 100vh;

  .svg {
    position: absolute;
    z-index: 1;
    bottom: 2rem;
    left: 2rem;
  }
  .privacy-container {
    height: calc(100% - 10rem);
    padding: 2rem 20%;
    z-index: 2;
    position: relative;
    width: 105%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.752);

    .title {
      font-size: 3rem;
      font-family: "P-600";
    }

    .subTitle {
      font-size: 1.8rem;
      font-family: "P-600";
    }

    .desc {
      font-size: 1.6rem;
      font-family: "P-300";
    }
  }
}
</style>
