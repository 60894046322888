<template>
  <div class="appointments">
    <div class="not-found" v-if="appointments && appointments.length <= 0">
      <img src="../assets/not_found.svg" alt="" />
      You don't have any appointments. Please click the '+' button below to make
      a new appointment
    </div>

    <div v-if="appointments && appointments.length > 0">
      <table border="0" v-if="!isMobile">
        <thead style="position : fixed: top:0 ">
          <tr class="head">
            <th style="width: 5%"></th>
            <th style="width: 15%">Patient</th>
            <th style="width: 15%">Date</th>
            <th style="width: 15%">Type</th>
            <!-- <th>Test & Status</th> -->
            <th class="three-grid">
              <div class="inner">
                <span>Test Name</span>
                <span>Status</span>
                <span>Report</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="index" v-for="(item, index) in appointments">
            <td class="index td">
              {{ index + 1 || "--" }}
            </td>
            <td class="td">
              {{ item.name || "--" }}
            </td>

            <td class="td">
              {{ moment(item.time).format("YYYY/MM/DD HH:mm ") || "--" }}
            </td>
            <td style="text-transform: capitalize" class="td">
              {{ item.mode ? item.mode.replace("_", " ") : "--" }}
            </td>

            <!-- <td style="text-transform: capitalize">
              <div v-for="item in item.tests" :key="item">
                &#9679; {{ item.name }} |
                {{ item.status ? item.status.replace("_", " ") : "--" }}
              </div>
            </td> -->

            <td class="three-grid">
              <div class="inner" v-for="test in item.tests" :key="test.id">
                <span>&#9679; {{ test.name }}</span>
                <span style="text-transform: capitalize">
                  {{ test.status ? test.status.replace("_", " ") : "--" }}</span
                >
                <div class="status">
                  <span v-if="test.status != 'completed'"
                    >Not Available Yet</span
                  >
                  <button v-else @click="downloadReport(item, test)">
                    Download
                  </button>
                </div>
              </div>
            </td>
            <!-- <td>
              <div class="cell-actions">
                <button
                  @click="onClickCancelAppointment(item)"
                  :class="{ disabled: item.status != 'pending' }"
                >
                  Check Reports
                </button>
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>

      <div class="mobile-list" v-else>
        <div class="item" v-for="(item, index) in appointments" :key="index">
          <div class="date-status">
            <span class="patient">{{ item.name }}</span>
            <span class="date">{{
              moment(item.time).format("YYYY/MM/DD HH:mm ") || "--"
            }}</span>
          </div>
          <div class="type">
            <img src="../assets/icons/ic_location_on.png" alt="" />
            {{ item.address }} , {{ item.city }}
          </div>
          <div class="type" style="margin-bottom: 2rem">
            <img src="../assets/icons/ic_category.png" alt="" />
            {{ item.mode ? item.mode.replace("_", " ") : "--" }}
          </div>

          <transition name="fade">
            <div class="tests" v-if="item.showTests">
              <div class="test-column">
                <div class="heading">Test</div>
                <div class="heading">Status</div>
                <div class="heading">Report</div>
              </div>
              <div
                class="test-column"
                v-for="test in item.tests"
                v-bind:key="test.id"
              >
                <div class="test-item">{{ test.name }}</div>
                <div class="test-item">{{ test.status }}</div>
                <div class="test-item">
                  <span v-if="test.status != 'completed'">Not Available</span>
                  <button class="bt" @click="downloadReport(item, test)" v-else>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </transition>
          <div class="actions">
            <button @click="item.showTests = !item.showTests">
              {{ item.showTests ? "Hide Status" : "Show Status" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <button class="add" @click="showBookingPopup = true">
      Create New Appointment
    </button>

    <transition>
      <book
        v-if="showBookingPopup"
        @onCancel="showBookingPopup = false"
        @onSuccess="onAppointmentSaveSuccess"
      />
    </transition>

    <transition>
      <cancelAppointment
        v-if="shpwCancelPopup"
        :appointmentId="cancelId"
        @onCancel="shpwCancelPopup = false"
        @onSuccess="onCancelSuccess"
      />
    </transition>
  </div>
</template>

<script>
import BookAppointment from "../components/BookAppointmentPopup.vue";
import CancelAppointmentPopup from "../components/CancelAppointmentPopup.vue";
import moment from "moment";
export default {
  name: "Appointments",
  components: {
    book: BookAppointment,
    cancelAppointment: CancelAppointmentPopup,
  },
  data() {
    return {
      cancelId: -1,
      shpwCancelPopup: false,
      showBookingPopup: false,
      appointments: [
        {
          id: 0,
          patient: "Ammad Amjad",
          test_name: "Blood Test",
          type: "Home Pickup",
          date: "13 July 2021",
          status: "pending",
        },
        {
          id: 0,
          patient: "Ammad Amjad",
          test_name: "Blood Test",
          type: "Home Pickup",
          date: "13 July 2021",
          status: "completed",
        },
        {
          id: 0,
          patient: "Ammad Amjad",
          test_name: "Blood Test",
          type: "Home Pickup",
          date: "13 July 2021",
          status: "cancelled",
        },
        {
          id: 0,
          patient: "Ammad Amjad",
          test_name: "Blood Test",
          type: "Home Pickup",
          date: "13 July 2021",
          status: "pending",
        },
        {
          id: 0,
          patient: "Ammad Amjad",
          test_name: "Blood Test",
          type: "Home Pickup",
          date: "13 July 2021",
          status: "pending",
        },
      ],
    };
  },
  methods: {
    downloadReport(appointment, item) {
      let url = `http://203.124.51.250:1244/lims/offlineReports/normaltestreport.aspx?format=${item.format}&computerno=${appointment.lims_computerno}&testdetailcode=${item.test_detail_code}&pageno=582GjeW21&checkpage=6`;
      window.open(url, "_blank");
    },
    onClickCancelAppointment(item) {
      this.cancelId = item.id;
      this.shpwCancelPopup = true;
    },
    getStatusColor(status) {
      console.log(status);
      if (status == "pending") {
        return "var(--clr-ylw)";
      } else if (status == "completed") {
        return "green";
      } else {
        return "gray";
      }
    },
    onCancelSuccess() {
      this.shpwCancelPopup = false;
      this.getAppointments();
    },
    onAppointmentSaveSuccess() {
      this.showBookingPopup = false;
      this.getAppointments();
    },
    getAppointments() {
      this.$HTTP
        .get(this.$URLS.APPOINTMENT.LIST)
        .then((res) => {
          var appointments = res.data.rows;
          appointments.map((item) => {
            item.showTests = false;
          });

          this.appointments = appointments;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.moment = moment;
    this.isMobile = screen.width <= 690;
  },
  mounted() {
    this.getAppointments();
    this.isMobile = screen.width <= 690;
  },
};
</script>

<style lang="scss" scoped>
.three-grid {
  width: 50%;

  .inner {
    height: 3.5rem;
    line-height: 3.5rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .status {
      button {
        background: transparent;
        outline: none;
        border: none;
        font-family: "P-500";
        color: orange;
        cursor: pointer;
      }
    }
  }
}
.appointments {
  width: 100%;
  height: auto;
  overflow-y: scroll;
  padding: 2rem;
  position: relative;

  .not-found {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    flex-direction: center;
    align-items: center;
    font-size: 1.2rem;
    color: gray;
    font-family: "P-400";
    padding: 2rem;
    img {
      width: 40vw;
      margin: auto;
      margin-bottom: 2rem;
    }
  }

  table {
    z-index: 1;
    width: 100%;
    overflow-y: scroll;
    transition: all 0.25s;
    background: transparent;
    position: relative;

    td {
      color: black;
      font-family: "P-300", sans-serif;
      height: 5rem;
      border-bottom: 0.1rem solid #dbdde0;
      font-size: 1.3rem;
      text-align: left;
      padding: 0rem 1rem;
    }

    tr:hover {
      background: rgba(128, 128, 128, 0.02);
    }
    th {
      color: gray;
      font-family: "P-700", sans-serif;
      text-transform: uppercase;
      height: 5rem;
      border-bottom: 0.1rem solid #dbdde0;
      font-size: 1.2rem;
      text-align: left;
      padding: 0rem 1rem;
    }
    th {
      background: white;
      position: sticky;
      top: 0; /* Don't forget this, required for the stickiness */
    }

    .index {
      text-align: center;
      color: var(--clr-ylw);
      font-family: "P-700", sans-serif;
      font-size: 1.2rem;
      border-right: 0.1rem solid lightgray;
    }

    .cell-actions {
      display: flex;
      flex-wrap: wrap;

      button {
        background: none;
        border: 0.1rem solid var(--clr-ylw);
        padding: 0.8rem;
        border-radius: 0.4rem;
        color: var(--clr-ylw);
        transition: 0.25s all ease-in;

        &:hover {
          background: var(--clr-ylw);
          color: white;
        }
      }

      .disabled {
        border-color: gray;
        color: gray;

        &:hover {
          cursor: not-allowed;
          background: transparent;
          color: gray;
        }
      }
    }
  }

  .mobile-list {
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;

    .item {
      display: flex;
      flex-direction: column;
      background: white;
      width: 100%;
      box-shadow: 0.1rem 0.1rem 0.3rem 0.3rem rgba(0, 0, 0, 0.1);
      border-radius: 0.8rem;
      margin-bottom: 2rem;
      overflow: hidden;
      transition: 0.2s all ease-in-out !important;

      .date-status {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 1.2rem;
        font-family: "P-600", sans-serif;
        padding: 1.4rem 1.2rem 0rem 1.2rem;
        color: lightgray;

        .status {
          text-transform: uppercase;
        }

        .pending {
          color: #fff176;
        }

        .completed {
          color: #1de9b6;
        }

        .cancelled {
          color: #ef5350;
        }

        .patient {
          font-size: 1.3rem;
          font-family: "P-700", sans-serif;
          color: black;
        }
      }

      .test,
      .type {
        font-size: 1.2rem;
        font-family: "P-400", sans-serif;
        color: black;
        padding: 0rem 1.2rem;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        margin-top: 1rem;

        img {
          height: 1.5rem;
          margin-right: 1rem;
        }
      }

      .tests {
        background: #fff3e0;
        display: flex;
        flex-direction: column;

        .test-column {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          .heading {
            font-size: 1.1rem;
            font-family: "P-600";
            background: #ffe0b2;
            padding: 0.6rem;
            text-transform: uppercase;
          }

          .test-item {
            font-size: 1.1rem;
            font-family: "P-400";
            padding: 0.6rem;
            overflow-x: hidden;
          }

          .bt {
            background: transparent;
            border: none;
            outline: none;
            cursor: pointer;
            font-family: "P-600";
            color: orange;
            font-size: 1.2rem;
          }
        }
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background: #f5f5f5;

        button {
          width: 100%;

          border: none;
          font-family: "P-600", sans-serif;
          font-size: 1.2rem;
          text-transform: uppercase;
          padding: 1.4rem 0rem;
          color: var(--clr-ylw);
          transition: 0.25s all ease-in;
        }
      }
    }
  }

  .add {
    height: 5rem;
    padding: 1rem;
    line-height: 3rem;
    font-size: 1.4rem;
    background: var(--clr-ylw);
    color: white;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    text-align: center;
    z-index: 2;
    border: none;
    border-radius: 4rem;
    padding: 0rem 2em;
    box-shadow: 0.1rem 0.1rem 0.3rem 0.3rem rgba(0, 0, 0, 0.1);
  }
}
</style>
